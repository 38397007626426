import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import Modal from 'react-modal';
import { loadStripe } from '@stripe/stripe-js';
import { useLocation, useNavigate } from 'react-router-dom';

function Scooters() {
  const [scooters, setScooters] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [renterId, setRenterId] = useState('');
  const [renterEmail, setRenterEmail] = useState('');
  const [selectedScooterId, setSelectedScooterId] = useState(null);

  const [sessionId, setSessionId] = useState(null);
  const stripePromise = loadStripe('pk_test_51P3BUfHF2q0uLdNFQYPqcymMBabgB7PYD8p0B9Vqd8O3IVMYTuczgdQjxJZeBqLbKyhee7sf3Nqh8oIAslCgo9RA00qzwbfUOJ');
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const paymentIntentSessionId = queryParams.get('session_id');
  const [reservingCarId, setReservingCarId] = useState(null);
  const navigate = useNavigate();

  /*const openModal = (carId) => {
    setSelectedCarId(carId);
    setModalIsOpen(true);
  };*/

  const closeModal = () => {
    // Optimistically update the cars state to reflect the new reservation status of the car
    const updatedCars = scooters.map(scooter => {
      if (scooter.carId === selectedScooterId) {
        return { ...scooter, reserved: true };
      } else {
        return scooter;
      }
    });
  
    setScooters(updatedCars, () => {
      // Close the modal after the state update has been applied
      setModalIsOpen(false);
    });
    setReservingCarId(null);
    navigate('/');
  };

  const fetchScooters = () => {
    fetch(`https://my-worker.tonychen9321.workers.dev/scooters?${Date.now()}`, { cache: 'no-cache' })
      .then(response => response.json())
      .then(data => {
        console.log('Scooter data:', data); // Log the scooter data
        const scootersOnly = data.filter(item => item.type === 'scooter');
        console.log('Scooter photo URLs:', scootersOnly.map(scooter => scooter.photo)); // Log the photo URLs
        setScooters(scootersOnly);
      });
  };

  useEffect(() => {
    fetchScooters();
  }, []);

  /*useEffect(() => {
    if (paymentIntentSessionId) {
      // Fetch the payment intent status from your server
      fetch(`https://my-worker.tonychen9321.workers.dev/payment-intent-status/${paymentIntentSessionId}`)
        .then(response => response.json())
        .then(data => {
          // Set the session ID, selected car ID, and open the modal
          setSelectedCarId(data.carId); // Set the selected car ID
          setSessionId(paymentIntentSessionId); // Set the session ID
          setModalIsOpen(true); // Open the modal
        })
        .catch(error => console.error('Error fetching payment intent status:', error));
    }
  }, [paymentIntentSessionId]);*/

  useEffect(() => {
    if (paymentIntentSessionId) {
      // Set the session ID and open the modal
      const storedCarId = localStorage.getItem('selectedScooterId');
      setSelectedScooterId(storedCarId);
      setSessionId(paymentIntentSessionId); // Set the session ID
      setModalIsOpen(true); // Open the modal
    }
  }, [paymentIntentSessionId]);

  const [showSuccessModal, setShowSuccessModal] = useState(false);

  const closeSuccessModal = () => {
    setShowSuccessModal(false);
    setModalIsOpen(false);
    navigate('/');
  };

  const [rentedCar, setRentedCar] = useState(null);

  useEffect(() => {
    if (rentedCar) {
      fetchScooters();
    }
  }, [rentedCar]);

  const reserveCar = async (e, scooterId) => {
    setReservingCarId(scooterId);
    console.log(`Reserving car with ID: ${scooterId}`);
    setSelectedScooterId(scooterId);
    localStorage.setItem('scooterId', scooterId);
    console.log(`Stored carId: ${scooterId}`);
    localStorage.setItem('selectedScooterId', scooterId);
    console.log(`Selected Car ID: ${selectedScooterId}`); 
    try {
      const response = await fetch(`https://my-worker.tonychen9321.workers.dev/scooters/${scooterId}/create-payment-intent`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ // Add the renterId, renterEmail, and carId to the request body
          renterId,
          renterEmail,
          scooterId,
        }),
      });
      const text = await response.text(); // Get the response as text
      console.log('Server response:', text); // Log the server response
      const data = JSON.parse(text); // Parse the response as JSON
      console.log('Parsed server response:', data); // Log the parsed server response
      const stripe = await stripePromise;
      const { error } = await stripe.redirectToCheckout({
        sessionId: data.sessionId,
      });

      if (error) {
        console.error('Error redirecting to Stripe Checkout:', error); // Log the Stripe redirect error
      } else {
        navigate('/checkout/scooter');
      }
    } catch (error) {
      console.error('Error in reserveCar function:', error);
    }
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    if (renterId && renterEmail) {
      try {
        const storedCarId = localStorage.getItem('carId'); // Retrieve the car ID from local storage
        console.log(`Retrieved carId: ${storedCarId}`);
        console.log(`Session ID: ${sessionId}`); 
        console.log(`Renter ID: ${renterId}`); 
        console.log(`Renter Email: ${renterEmail}`);
        console.log(`Selected Car ID: ${selectedScooterId}`); 

        const response = await fetch(`https://my-worker.tonychen9321.workers.dev/update-payment-intent-metadata`, {
        method: 'POST',
        headers: {
        'Content-Type': 'application/json',
        },
        body: JSON.stringify({ sessionId, renterId, renterEmail, scooterId: storedCarId}),
        });

        if (!response.ok) {
          throw new Error('Error reserving car');
        }

        // Store the renter's information in the KV store
        await fetch(`https://my-worker.tonychen9321.workers.dev/update-renter-info`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ sessionId, renterId, renterEmail, scooterId: storedCarId}),
        });

        const rentedCar = scooters.find(car => car.carId === selectedScooterId);
        if (rentedCar) {
          console.log('Rented car:', rentedCar);
          setRentedCar(rentedCar);
          setShowSuccessModal(true); // Show the success modal
        } else {
          console.error('Error: No car found with the selected ID');
        }
      } catch (error) {
        console.error('Error in handleFormSubmit function:', error);
      }
    } else {
      alert('You must enter your ID or passport number and email to reserve a car.');
    }
  };

  return (
    <Container className="mt-5">
      <Row>
        <Col>
          <h1>Scooter Listing</h1>
        </Col>
      </Row>
      {scooters.map((scooter, index) => (
        <Row key={index} className="mb-3">
          <Col className="ml-3">
            <h2>{scooter.name}</h2>
            <Image src={scooter.photo} alt={scooter.name} fluid />
            <p>Model: {scooter.model}</p>
            <p>Year: {scooter.year}</p>
            <p>Seats: {scooter.seats}</p>
            <p>Reserved: {scooter.reserved ? 'Yes' : 'No'}</p>
            {!(scooter.reserved || scooter.carId === reservingCarId) && <button onClick={(e) => reserveCar(e, scooter.carId)}>Reserve</button>}
          </Col>
        </Row>
      ))}
      <Modal 
        isOpen={modalIsOpen} 
        onRequestClose={closeModal}
        style={{
          content: {
            width: '80%', 
            height: '80%', 
            margin: 'auto', 
            maxWidth: '500px', 
            maxHeight: '600px', 
            overflow: 'auto'
          }
        }}
      >
        <h2>Reserve Scooter</h2>
        <form onSubmit={handleFormSubmit}>
          <label htmlFor="renterId">
            ID or Passport Number:
            <input type="text" id="renterId" value={renterId} onChange={e => setRenterId(e.target.value)} required />
          </label>
          <label htmlFor="renterEmail">
            Email:
            <input type="email" id="renterEmail" value={renterEmail} onChange={e => setRenterEmail(e.target.value)} required />
          </label>
          <button type="submit">Reserve</button>
        </form>
        <button onClick={closeModal}>Close</button>
      </Modal>
      <Modal 
        isOpen={showSuccessModal} 
        onRequestClose={closeSuccessModal}
        style={{
          content: {
            width: '80%', 
            height: '80%', 
            margin: 'auto', 
            maxWidth: '500px', 
            maxHeight: '600px', 
            overflow: 'auto'
          }
        }}
      >
        <h2>Reservation Successful</h2>
        {rentedCar && (
          <>
            <p>You have reserved the {rentedCar.name}.</p>
            <p>Your ID is {renterId} and your email is {renterEmail}.</p>
            <p>You can now safely click the close button, there will be confirmation email sent to your email address and it will take a bit time for the status to be updated</p>
          </>
        )}
        <button onClick={closeSuccessModal}>Close</button>
      </Modal>
      <Row>
        <Col>
          <button onClick={fetchScooters}>Refresh</button>
        </Col>
      </Row>
    </Container>
  );
}

export default Scooters;