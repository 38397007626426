import React, { useState } from 'react'; // Add useState here
import { Form, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

function BookingForm({ step, handleStartDateChange, handleEndDateChange, handleVehicleChange, handlePickupLocationChange, prevStep, nextStep, startDate, endDate, vehicle, pickupLocation, numTravelers, handleNumTravelersChange }) {
  const [error, setError] = useState(''); // Add this line
  const today = new Date().toISOString().split('T')[0]; // Add this line
  const handleNextStep = (event) => {
    event.preventDefault();
    let errorMessage = '';
    if (step === 1 && (Number(numTravelers) < 1 || !Number.isInteger(Number(numTravelers)) || !numTravelers)) {
      errorMessage = 'Invalid input: The number of travelers must be an integer and at least 1.';
    } else if (step === 2) {
      if (!startDate || !endDate) {
        errorMessage = 'Invalid input: Both start date and end date must be provided.';
      } else if (new Date(startDate) > new Date(endDate)) {
        errorMessage = 'Invalid input: Start date cannot be later than end date.';
      } else if (new Date(startDate) < new Date(today)) {
        errorMessage = 'Invalid input: Start date cannot be earlier than today.';
      }
    } else if (step === 3 && vehicle === '') {
      errorMessage = 'Invalid input: Please choose a type of vehicle.';
    } else if (step === 4 && pickupLocation === '') {
      errorMessage = 'Invalid input: Please choose a pick-up location.';
    }
    if (errorMessage) {
      setError(errorMessage);
    } else {
      setError('');
      nextStep();
    }
  };

  return (
    <Form>
      {error && <p>{error}</p>}
      {step === 1 && (
        <div>
          <Form.Group controlId="numTravelers">
            <Form.Label>How many people are traveling?</Form.Label>
            <Form.Control type="number" min="1" value={numTravelers} onChange={handleNumTravelersChange} />
          </Form.Group>
          <Button variant="primary" type="button" onClick={handleNextStep}>Next</Button>
        </div>
      )}
      {step === 2 && (
        <div>
          <Form.Group controlId="startDate">
            <Form.Label>Start Date:</Form.Label>
            <input type="date" id="startDate" name="startDate" min={today} value={startDate} onChange={handleStartDateChange} />
          </Form.Group>
          <Form.Group controlId="endDate">
            <Form.Label>End Date:</Form.Label>
            <Form.Control type="date" min={startDate} value={endDate} onChange={handleEndDateChange} />
          </Form.Group>
          <Button variant="primary" type="button" onClick={prevStep}>Back</Button>
          <Button variant="primary" type="button" onClick={handleNextStep}>Next</Button>
        </div>
      )}
      {step === 3 && (
        <div>
          <Form.Group controlId="vehicle">
            <Form.Label>Car or Scooter?</Form.Label>
            <Form.Control as="select" value={vehicle} onChange={handleVehicleChange}>
              <option value="">--Please choose an option--</option>
              <option value="scooter">Scooter</option>
              <option value="car">Car</option>
            </Form.Control>
          </Form.Group>
          <Button variant="primary" type="button" onClick={prevStep}>Back</Button>
          <Button variant="primary" type="button" onClick={handleNextStep}>Next</Button>
        </div>
      )}
      {step === 4 && (
        <div>
          <Form.Group controlId="pickupLocation">
            <Form.Label>Pick-up Location:</Form.Label>
            <Form.Control as="select" value={pickupLocation} onChange={handlePickupLocationChange}>
              <option value="">--Please choose a location--</option>
              <option value="jiaoxi">Jiaoxi</option>
              <option value="yilan">Yilan</option>
              <option value="luodong">Luo Dong</option>
            </Form.Control>
          </Form.Group>
          <Button variant="primary" type="button" onClick={prevStep}>Back</Button>
          <Button variant="primary" type="button" onClick={handleNextStep}>Next</Button>
        </div>
      )}
    </Form>
  );
}

export default BookingForm;