import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import Modal from 'react-modal';
import { loadStripe } from '@stripe/stripe-js';
import { useLocation, useNavigate } from 'react-router-dom';

function Cars() {
  const [cars, setCars] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [renterId, setRenterId] = useState('');
  const [renterEmail, setRenterEmail] = useState('');
  const [selectedCarId, setSelectedCarId] = useState(null);
  const [sessionId, setSessionId] = useState(null);
  const stripePromise = loadStripe('pk_test_51P3BUfHF2q0uLdNFQYPqcymMBabgB7PYD8p0B9Vqd8O3IVMYTuczgdQjxJZeBqLbKyhee7sf3Nqh8oIAslCgo9RA00qzwbfUOJ');
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const paymentIntentSessionId = queryParams.get('session_id');
  const [reservingCarId, setReservingCarId] = useState(null);
  const navigate = useNavigate();

  const closeModal = () => {
    // Optimistically update the cars state to reflect the new reservation status of the car
    const updatedCars = cars.map(car => {
      if (car.carId === selectedCarId) {
        return { ...car, reserved: true };
      } else {
        return car;
      }
    });
  
    setCars(updatedCars, () => {
      // Close the modal after the state update has been applied
      setModalIsOpen(false);
    });
    setReservingCarId(null);
    navigate('/');
  };

  const fetchCars = () => {
    fetch(`https://my-worker.tonychen9321.workers.dev/cars?${Date.now()}`, { cache: 'no-cache' })
      .then(response => response.json())
      .then(data => {
        console.log('Car data:', data); // Log the car data
        const carsOnly = data.filter(item => item.type === 'car'); // No need to parse the car data again
        setCars(carsOnly);
      });
  };

  useEffect(() => {
    fetchCars();
  }, []);

  /*useEffect(() => {
    if (paymentIntentSessionId) {
      // Fetch the payment intent status from your server
      fetch(`https://my-worker.tonychen9321.workers.dev/payment-intent-status/${paymentIntentSessionId}`)
        .then(response => response.json())
        .then(data => {
          // Set the session ID, selected car ID, and open the modal
          setSelectedCarId(data.carId); // Set the selected car ID
          setSessionId(paymentIntentSessionId); // Set the session ID
          setModalIsOpen(true); // Open the modal
        })
        .catch(error => console.error('Error fetching payment intent status:', error));
    }
  }, [paymentIntentSessionId]);*/

  useEffect(() => {
    if (paymentIntentSessionId) {
      // Set the session ID and open the modal
      const storedCarId = localStorage.getItem('selectedCarId');
      setSelectedCarId(storedCarId);
      setSessionId(paymentIntentSessionId); // Set the session ID
      setModalIsOpen(true); // Open the modal
    }
  }, [paymentIntentSessionId]);

  const [showSuccessModal, setShowSuccessModal] = useState(false);

  const closeSuccessModal = () => {
    setShowSuccessModal(false);
    setModalIsOpen(false);
    navigate('/');
  };

  const [rentedCar, setRentedCar] = useState(null);

  useEffect(() => {
    if (rentedCar) {
      fetchCars();
    }
  }, [rentedCar]);

  const reserveCar = async (e, carId) => {
    setReservingCarId(carId);
    console.log(`Reserving car with ID: ${carId}`);
    setSelectedCarId(carId);
    localStorage.setItem('carId', carId);
    console.log(`Stored carId: ${carId}`);
    localStorage.setItem('selectedCarId', carId);
    console.log(`Selected Car ID: ${selectedCarId}`); 
    try {
      const response = await fetch(`https://my-worker.tonychen9321.workers.dev/cars/${carId}/create-payment-intent`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ // Add the renterId, renterEmail, and carId to the request body
          renterId,
          renterEmail,
          carId,
        }),
      });
      const text = await response.text(); // Get the response as text
      console.log('Server response:', text); // Log the server response
      const data = JSON.parse(text); // Parse the response as JSON
      console.log('Parsed server response:', data); // Log the parsed server response
      const stripe = await stripePromise;
      const { error } = await stripe.redirectToCheckout({
        sessionId: data.sessionId,
      });

      if (error) {
        console.error('Error redirecting to Stripe Checkout:', error); // Log the Stripe redirect error
      }
    } catch (error) {
      console.error('Error in reserveCar function:', error);
    }
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    if (renterId && renterEmail) {
      try {
        const storedCarId = localStorage.getItem('carId'); // Retrieve the car ID from local storage
        console.log(`Retrieved carId: ${storedCarId}`);
        console.log(`Session ID: ${sessionId}`); 
        console.log(`Renter ID: ${renterId}`); 
        console.log(`Renter Email: ${renterEmail}`);
        console.log(`Selected Car ID: ${selectedCarId}`); 

        const response = await fetch(`https://my-worker.tonychen9321.workers.dev/update-payment-intent-metadata`, {
        method: 'POST',
        headers: {
        'Content-Type': 'application/json',
        },
        body: JSON.stringify({ sessionId, renterId, renterEmail, carId: storedCarId}),
        });

        if (!response.ok) {
          throw new Error('Error reserving car');
        }

        // Store the renter's information in the KV store
        await fetch(`https://my-worker.tonychen9321.workers.dev/update-renter-info`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ sessionId, renterId, renterEmail, carId: storedCarId}),
        });

        const rentedCar = cars.find(car => car.carId === selectedCarId);
        if (rentedCar) {
          console.log('Rented car:', rentedCar);
          setRentedCar(rentedCar);
          setShowSuccessModal(true); // Show the success modal
        } else {
          console.error('Error: No car found with the selected ID');
        }
      } catch (error) {
        console.error('Error in handleFormSubmit function:', error);
      }
    } else {
      alert('You must enter your ID or passport number and email to reserve a car.');
    }
  };

  return (
    <Container className="mt-5">
      <Row>
        <Col>
          <h1>Car Listing</h1>
        </Col>
      </Row>
      {cars.map((car, index) => (
        <Row key={index} className="mb-3">
          <Col className="ml-3">
            <h2>{car.name}</h2>
            <Image src={car.photo} alt={car.name} fluid /> {/* Display the photo */}
            <p>Model: {car.model}</p>
            <p>Year: {car.year}</p>
            <p>Seats: {car.seats}</p>
            <p>Reserved: {car.reserved ? 'Yes' : 'No'}</p> {/* Display the reserved status */}
            {!(car.reserved || car.carId === reservingCarId) && <button onClick={(e) => reserveCar(e, car.carId)}>Reserve</button>}
          </Col>
        </Row>
      ))}
      <Modal 
        isOpen={modalIsOpen} 
        onRequestClose={closeModal}
        style={{
          content: {
            width: '80%', 
            height: '80%', 
            margin: 'auto', 
            maxWidth: '500px', 
            maxHeight: '600px', 
            overflow: 'auto'
          }
        }}
      >  
        <h2>Reserve Car</h2>
        <form onSubmit={handleFormSubmit}>
          <label htmlFor="renterId">
            ID or Passport Number:
            <input type="text" id="renterId" value={renterId} onChange={e => setRenterId(e.target.value)} required />
          </label>
          <label htmlFor="renterEmail">
            Email:
            <input type="email" id="renterEmail" value={renterEmail} onChange={e => setRenterEmail(e.target.value)} required />
          </label>
          <button type="submit">Reserve</button>
        </form>
        <button onClick={closeModal}>Close</button>
      </Modal>
      <Modal 
        isOpen={showSuccessModal} 
        onRequestClose={closeSuccessModal}
        style={{
          content: {
            width: '80%', 
            height: '80%', 
            margin: 'auto', 
            maxWidth: '500px', 
            maxHeight: '600px', 
            overflow: 'auto'
          }
        }}
      >
        <h2>Reservation Successful</h2>
        {rentedCar && (
          <>
            <p>You have reserved the {rentedCar.name}.</p>
            <p>Your ID is {renterId} and your email is {renterEmail}.</p>
            <p>You can now safely click the close button, there will be confirmation email sent to your email address and it will take a bit time for the status to be updated</p>
          </>
        )}
        <button onClick={closeSuccessModal}>Close</button>
      </Modal>
      <Row>
        <Col>
          <button onClick={fetchCars}>Refresh</button>
        </Col>
      </Row>
    </Container>
  );
}

export default Cars;