import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import { Navbar, Nav } from 'react-bootstrap';
import BookingForm from './BookingForm';
import './App.css';
import CarsComponent from './Cars';
import AddCar from './AddCar';
import Scooters from './Scooter'; // Import the Scooters component
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col } from 'react-bootstrap';
//import { Link} from 'react-router-dom';

/*function Checkout() {
  const location = useLocation();
  const sessionId = new URLSearchParams(location.search).get('session_id');

  useEffect(() => {
    if (sessionId) {
      // Fetch the Checkout Session to display the transaction details
      fetch(`https://my-worker.tonychen9321.workers.dev/checkout-session?sessionId=${sessionId}`)
        .then(response => response.json())
        .then(data => {
          // Display the transaction details
          console.log('Checkout Session:', data);
        });
    }
  }, [sessionId]);

  return (
    <div>
      <h1>Checkout</h1>
      {sessionId ? <p>Transaction ID: {sessionId}</p> : <p>No transaction ID</p>}
    </div>
  );
}*/

function App() {
  const [cars, setCars] = useState([]);

  useEffect(() => {
    fetch('https://my-worker.tonychen9321.workers.dev/cars')
      .then(response => response.json())
      .then(data => setCars(data));
  }, []);

  return (
    <Router>
      <Navbar bg="light" expand="lg">
      <div className="ml-5">
        <Navbar.Brand className="navbar-brand">Safe&Stable Car Rental</Navbar.Brand>
      </div>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
            <Nav.Link href="/">Home</Nav.Link>
            <Nav.Link href="/cars">Cars</Nav.Link>
            <Nav.Link href="/scooters">Scooters</Nav.Link>
            <Nav.Link href="/add-car">Add Car</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>

      <Routes>
        <Route path="/cars" element={<CarsComponent key={Date.now()} cars={cars} />} />
        <Route path="/scooters" element={<Scooters key={Date.now()} cars={cars} />} />
        <Route path="/add-car" element={<AddCar />} />
        <Route path="/" element={<Home />} />
        {/*<Route path="/checkout" element={<CarsComponent cars={cars} />} />*/}
        <Route path="/checkout" element={<CarsComponent type="car" cars={cars} />} />
        <Route path="/checkout/scooter" element={<Scooters />} />
      </Routes>
    </Router>
  );
}

function Home() {
  const location = useLocation();
  const [step, setStep] = useState(1);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [vehicle, setVehicle] = useState("");
  const [pickupLocation, setPickupLocation] = useState("");
  const [numTravelers, setNumTravelers] = useState(1);

  const handleStartDateChange = (e) => setStartDate(e.target.value);
  const handleEndDateChange = (e) => setEndDate(e.target.value);
  const handleVehicleChange = (e) => setVehicle(e.target.value);
  const handlePickupLocationChange = (e) => setPickupLocation(e.target.value);
  const handleNumTravelersChange = (e) => setNumTravelers(e.target.value);

  const nextStep = () => setStep(prevStep => prevStep + 1);
  const prevStep = () => setStep(prevStep => prevStep - 1);

  // Reset the form whenever the component is re-rendered
  useEffect(() => {
    setStep(1);
    setStartDate("");
    setEndDate("");
    setVehicle("");
    setPickupLocation("");
    setNumTravelers(1);
  }, [location.state]);


  return (
    <Container>
      <Row>
        <Col xs={12} md={8}>
          <h1>Welcome to our rental service!</h1>
        </Col>
        <Col xs={6} md={4}>
          <BookingForm 
            step={step} 
            handleStartDateChange={handleStartDateChange}
            handleEndDateChange={handleEndDateChange}
            handleVehicleChange={handleVehicleChange}
            handlePickupLocationChange={handlePickupLocationChange}
            prevStep={prevStep}
            nextStep={nextStep}
            startDate={startDate}
            endDate={endDate}
            vehicle={vehicle}
            pickupLocation={pickupLocation}
            numTravelers={numTravelers}
            handleNumTravelersChange={handleNumTravelersChange}
          />
        </Col>
      </Row>
    </Container>
  );
}

export default App;