import React, { useState, useEffect } from 'react';
import { Container, Form, Button, Alert } from 'react-bootstrap';

function AddCar() {
  const [newCar, setNewCar] = useState({ carId: '', name: '', model: '', year: '', seats: '', type: 'car', reserved: false, photo: '' });
  const [isSuccess, setIsSuccess] = useState(false);
  const [isDuplicate, setIsDuplicate] = useState(false);
  const [cars, setCars] = useState([]);

  useEffect(() => {
    fetch('https://my-worker.tonychen9321.workers.dev/cars', { cache: 'no-cache' })
      .then(response => response.json())
      .then(data => {
        const carsOnly = data.filter(item => item.type === 'car');
        setCars(carsOnly);
      });
  }, []);

  // Update the carId in the handleInputChange function
const handleInputChange = (event) => {
  let value = event.target.value;

  if (event.target.type === 'checkbox') {
    value = event.target.checked;
  } else if (event.target.type === 'file') {
    value = event.target.files[0];
  }

  // Update the carId when the name, model, or year changes
  if (['name', 'model', 'year'].includes(event.target.name)) {
    setNewCar(prevCar => {
      const updatedCar = { ...prevCar, [event.target.name]: value };
      updatedCar.carId = `${updatedCar.name}-${updatedCar.model}-${updatedCar.year}`;
      return updatedCar;
    });
  } else {
    setNewCar({ ...newCar, [event.target.name]: value });
    }
  };

  const handleAddCar = async () => {
    try {
      // Check if the new car already exists in the list
      const isDuplicateCar = cars.some(car => car.carId === newCar.carId);
  
      if (isDuplicateCar) {
        setIsDuplicate(true);
        setIsSuccess(false);
      } else {
        // If the car doesn't exist, add it to the list
        // If the car data includes a photo, upload the image first
        if (newCar.photo) {
          const formData = new FormData();
          formData.append('image', newCar.photo);
          formData.append('carId', newCar.carId);
        
          const imageResponse = await fetch('https://my-worker.tonychen9321.workers.dev/storeCarImage', {
            method: 'POST',
            body: formData,
          });
          const imageJson = await imageResponse.json();
          console.log('Image response:', imageJson); // Log the image response
  
          if (imageJson.message !== 'Car image stored') {
            throw new Error(`HTTP error! status: ${imageJson.status}`);
          }
        }
  
        const storeResponse = await fetch('https://my-worker.tonychen9321.workers.dev/storeCarData', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(newCar), // Include the carId property in the JSON object
        });
        const json = await storeResponse.json();
        console.log('Store response:', json); // Log the store response
  
        if (json.message === 'Car stored successfully') {
          console.log('Car data stored successfully', json);
          setIsSuccess(true);
          setIsDuplicate(false);
          setCars([...cars, newCar]);
        } else {
          throw new Error(`HTTP error! status: ${json.status}`);
        }
      }
    } catch (error) {
      console.error('Error:', error);
      setIsSuccess(false);
      setIsDuplicate(false);
    }
  };

  return (
    <Container>
      <h1>Add a new vehicle</h1>
      <Form>
        <Form.Group controlId="formName">
          <Form.Label>Name</Form.Label>
          <Form.Control type="text" name="name" value={newCar.name} onChange={handleInputChange} placeholder="Name" />
        </Form.Group>

        <Form.Group controlId="formModel">
          <Form.Label>Model</Form.Label>
          <Form.Control type="text" name="model" value={newCar.model} onChange={handleInputChange} placeholder="Model" />
        </Form.Group>

        <Form.Group controlId="formYear">
          <Form.Label>Year</Form.Label>
          <Form.Control type="text" name="year" value={newCar.year} onChange={handleInputChange} placeholder="Year" />
        </Form.Group>

        <Form.Group controlId="formSeats">
          <Form.Label>Number of seats</Form.Label>
          <Form.Control type="text" name="seats" value={newCar.seats} onChange={handleInputChange} placeholder="Number of seats" />
        </Form.Group>

        <Form.Group controlId="formType">
          <Form.Label>Type</Form.Label>
          <Form.Control as="select" name="type" value={newCar.type} onChange={handleInputChange}>
            <option value="car">Car</option>
            <option value="scooter">Scooter</option>
          </Form.Control>
        </Form.Group>

        <Form.Group controlId="formReserved">
          <Form.Check type="checkbox" name="reserved" checked={newCar.reserved} onChange={handleInputChange} label="Reserved" />
        </Form.Group>

        <Form.Group controlId="formPhoto">
          <Form.Label>Photo</Form.Label>
          <Form.Control type="file" name="photo" accept="image/*" capture="environment" onChange={handleInputChange} />
        </Form.Group>

        <Button variant="primary" onClick={handleAddCar}>
          Add Vehicle
        </Button>
      </Form>
      {isSuccess && <Alert variant="success">Vehicle added successfully!</Alert>}
      {isDuplicate && <Alert variant="danger">A vehicle with these details already exists!</Alert>}
    </Container>
  );
}

export default AddCar;